@use '../base/vars' as *;

.campaign_page{
    .section_title{
        line-height: 1.45;

        &::after {
            width: 350px;

            @include media_query(){
                width: 260px;
            }
        }
    }
}

.campaign_contents{
    max-width: 800px;
    margin: 40px auto 0;
    padding: 40px;
    background-color: #fff;
    box-shadow: 12px 12px 0px rgba(0, 0, 0, 0.05);
    
    @include media_query(1100){
        max-width: 760px;
    }

    @include media_query(){
        padding: 24px 16px;
        box-shadow: 6px 6px 0px rgba(0, 0, 0, 0.05);
    }

    & + .campaign_contents{
        margin-top: 40px;
    }

    h3{
        text-align: center;
        @include rem(24);
        line-height: 1.3;
        letter-spacing: 0.01em;
        margin-bottom: 24px;
    }
    
    .campaign_closed{
        @include rem(20);
        line-height: 1.35;
        font-weight: 700;
        color: #F07100;
        text-align: center;
        margin: 24px 0;
    }

    .campaign_inner{

        & + .campaign_inner{
            margin-top: 20px;
            border-top: 1px dashed #334156;
            padding-top: 20px;
        }

        h4{
            @include rem(18);
            line-height: 1.4;
            margin-bottom: 16px;

            &.indent{
                padding-left:1.3em;
                text-indent:-1.3em;
            }
        }

        h5{
            @include rem(16);
            margin-bottom: 10px;
        }

        h6{
            @include rem(16);
            margin: 10px 0 5px;
        }

        p{
            @include rem(16);
            line-height: 1.75;

            small{
                display: block;
                @include rem(12);
                color: #777777;

                padding-left:1.3em;
                text-indent:-1.3em;
            }

            & + h5{
                margin-top: 16px;
            }
        }

        ul{
            padding-left: 1em;
            
            &.nondisc{
                list-style: none;
                padding-left: 0.2em;
            }

            li{
                @include rem(16);
                line-height: 1.75;
            }

            & + h5{
                margin-top: 20px;
            }
        }
    }
}

.btn_campaign_apply{
    margin-top: 24px;

    a{
        max-width: 260px;
        height: 40px;
        margin: 0 auto;
        display: grid;
        place-content: center;
        background: linear-gradient(93.29deg, #FF7A00 0%, #FFA800 102.53%);
        box-shadow: 3px 3px 0px rgba(0, 0, 0, 0.1);
        border-radius: 4px;
        @include rem(18);
        font-weight: bold;
        color: #fff;
        text-decoration: none;

        &:hover{
            opacity: .7;
        }
    }
}
