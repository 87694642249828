@use '../base/vars' as *;

html,
body{
    height: 100%;
}

.wrap{
    display: flex;
    flex-direction: column;
    min-height: 100%;
    //padding: 80px 0 0 0;

    @include media_query(){
        padding: 0 0 0 0;
    }
}





.header{
    position: fixed;
    top: 0;
    left: 0;
    z-index: 97;
    width: 100%;
    background: rgba(255, 255, 255, 0.9);
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.04);

    @include media_query(){
        background-color: #fff;
    }

    .header_inner{
        display: flex;
        align-items: center;
        max-width: 1366px;
        min-height: 80px;
        margin: 0 auto;
        padding: 16px 44px 16px 24px;

        @include media_query(1100){
            min-height: 65px;
            padding: 0 44px 0 24px;
        }

        @include media_query(820){
            min-height: 48px;
            padding: 5px 8px;
        }

        .logo{
            @include media_query(1100){
                margin-right: auto;
            }

            a{
                &:hover{
                    opacity: .7;
                }
            }
            
            img{
                width: 100%;
                max-width: 280px;
                height: auto;
                
                @include media_query(1100){
                    max-width: 180px;
                }
                
                @include media_query() {
                    max-width: 200px;
                }
            }
        }
    }
}





.navigation{
    margin-left: auto;

    @include media_query(850){
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        margin: 0;
        padding: 0 20px;
        // overflow: scroll;
        overflow-x: scroll;
        overflow-y: hidden;
        background: rgba(255, 255, 255, 0.9);
    }

    .navigation_ul{
        display: flex;
        align-items: center;
        gap: 34px;

        @include media_query(1260){
            gap: 10px;
        }

        & > li{
            position: relative;
            list-style: none;

            &:hover{
                & > a{
                    color: rgba(51, 65, 86, 0.5);
                }

                & > ul{
                    opacity: 1;
                    visibility: visible;

                    @include media_query(){
                        opacity: 0;
                        visibility: hidden;
                    }
                }
            }

            & > a{
                display: block;
                @include rem(14);
                text-decoration: none;
                color: $font-color;
                transition: color .25s;

                @include media_query(1260){
                    @include rem(14);
                    padding: 16px 5px;
                }

                @include media_query(){
                    white-space: nowrap;
                }

                &.current{
                    color: #BDBDBD;
                }
            }

            ul{
                opacity: 0;
                visibility: hidden;
                position: absolute;
                left: 0;
                top: calc(100% + 5px);
                box-shadow: 4px 4px 0px rgba(0, 0, 0, 0.08);
                border-radius: 4px;
                background-color: #fff;
                overflow: hidden;

                transition: opacity .4s, visibility .4s;

                li{
                    a{
                        display: block;
                        width: 100%;
                        @include rem(14);
                        color: #334156;
                        text-decoration: none;
                        white-space: nowrap;
                        padding: 10px;

                        &:hover{
                            background-color: #ECECEC;
                        }
                    }
                }
            }
        }
    }
}




.btn_application{
    position: relative;
    margin-left: 16px;
    
    cursor: pointer;

    @include media_query(1120){
        margin-left: 8px;
    }
    
    &:hover{
        ul{
            top: calc(100% + 8px);
            visibility: visible;
            opacity: 1;
            
            @include media_query(){
                visibility: hidden;
                opacity: 0;
            }
        }
    }

    & > a,
    & > span{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 172px;
        height: 48px;
        @include rem(18);
        font-weight: bold;
        text-decoration: none;
        color: #fff;
        background: linear-gradient(93.29deg, #FF7A00 0%, #FFA800 102.53%);
        box-shadow: 6px 6px 0px rgba(0, 0, 0, 0.1);
        border-radius: 4px;
        transition: opacity .25s;

        // &:hover {
        //     opacity: .7;
        // }
        
        @include media_query(1100){
            max-width: 104px;
            height: 38px;
            @include rem(14);
        }

        @include media_query(){
            width: 100px;
            height: 38px;
            
            //box-shadow: none;
            box-shadow: 3px 3px 0px rgba(0, 0, 0, 0.1);
        }
    }
    
    ul{
        visibility: hidden;
        opacity: 0;
        position: absolute;
        top: 100%;
        width: 100%;
        height: auto;
        list-style: none;

        background: rgba(255, 255, 255, 0.8);
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
        border-radius: 4px;
        padding: 11px;
        
        transition: top .4s, visibility .4s, opacity .4s;
        
        
        
        li{
            
            & + li{
                margin-top: 8px
            }
            
            a{
                display: grid;
                place-content: center;
                width: 150px;
                height: 42px;
                
                background: #FFFFFF;
                border: 1px solid #FF7A00;
                border-radius: 4px;
                font-weight: 500;
                font-size: 18px;
                line-height: 26px;
                text-align: center;
                color: #FF7A00;
                text-decoration: none;
                
                transition: background .25s, color .25s;
                
                &:hover{
                    background: #FF7A00;
                    color: #fff;
                }
            }
        }

    }
}

.btn_company{
    @extend .btn_application;
    margin-left: 45px;

    @include media_query(1120){
        margin-left: 20px;
    }

    & > a,
    & > span{
        background: linear-gradient(93.29deg, #0085FF 0%, #72E3FB 102.53%);
    }
    
    ul{
        li{
            a{
                border: 1px solid #0085FF;
                color: #0085FF;
                
                &:hover{
                    background: #0085FF;
                }
            }
        }
    }
}


.sp_application_btns{
    display: none;
    
    ul{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 10px;
        padding: 6px 0;
    }
    
    li{
        a{
            display: grid;
            place-content: center;
            width: 100%;
            height: 35px;
            
            background: #FFFFFF;
            border: 1px solid #FF7A00;
            border-radius: 4px;
            font-weight: 500;
            font-size: 14px;
            line-height: 26px;
            text-align: center;
            color: #FF7A00;
            text-decoration: none;
        }
    }
}

.sp_company_btns{
    display: none;
    
    ul{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 10px;
        padding: 6px 0;
    }
    
    li{
        a{
            display: grid;
            place-content: center;
            width: 100%;
            height: 35px;
            
            background: #FFFFFF;
            border: 1px solid #0085FF;
            border-radius: 4px;
            font-weight: 500;
            font-size: 14px;
            line-height: 26px;
            text-align: center;
            color: #0085FF;
            text-decoration: none;
        }
    }
}

.pc_btns{
    @include media_query(){
        display: none;
    }
}

.sp_btns{
    display: none;
    
    @include media_query(){
        display: block;
    }
}




.main {
    flex: 1;
}









.footer{
    background-color: #334156;

    .footer_inner{
        padding: 25px 25px 25px;

        ul{
            display: flex;
            justify-content: center;

            @include media_query(){
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                line-height: 1.4;
            }

            li{
                a{
                    @include rem(12);
                    color: #fff;
                    text-decoration: none;
                    transition: opacity .25s;

                    &:hover{
                        opacity: .7;
                    }
                }

                & + li{
                    a{
                        &::before{
                            content: "｜";

                            @include media_query(){
                                display: none;
                            }
                        }
                    }
                }
            }
        }

        .copy{
            text-align: center;
            margin-top: 30px;

            small{
                @include rem(12);
                line-height: 1.43;
                color: #fff;
            }
        }
    }
}










.sp_br{
    display: none;

    @include media_query(){
        display: block;
    }
}

.pc_br{
    display: block;

    @include media_query(){
        display: none;
    }
}




.decoration{
    position: absolute;
    z-index: -1;
    height: auto;
    @include media_query(){
        display: none;
        visibility: hidden;
    }
}







.banner{
    position: fixed;
    right: 0;
    bottom: -200px;
    z-index: 96;
    
    transition: bottom .4s, opacity .25s;
    
    &.inview{
        bottom: 0;
    }
    
    &.hidden{
        visibility: hidden;
        opacity: 0;
    }
    
    &:hover{
        opacity: .7;
    }
    
    img{
        max-width: 422px;
        height: auto;
        
        @include media_query(){
            max-width: 100%;
        }
    }
    
    .close{
        position: absolute;
        left: 5px;
        top: 50px;
        z-index: 100;
        padding: 3px;
        cursor: pointer;
        
        @include media_query(){
            top: 40px;
            padding: 10px;
        }
    }
}
