@use '../base/vars' as *;

.company_page{
    width: 100%;
    max-width: 960px;
    margin: 0 auto;
}

.pagetab{
    margin-top: 40px;
    
    // ul{
    //     display: grid;
    //     grid-template-columns: repeat(2, 1fr);
    //     border-bottom: solid 1px #0069FF;
    //     transform: perspective(0.5em) rotateX(0.5deg);
        
    //     li{
    //         list-style: none;
    //         background-color: #fff;
            
    //         &.current{
    //             background-color: #0069FF;
    //             a{
    //                 color: #fff;
    //             }
    //         }
            
    //         a{
    //             display: block;
    //             width: 100%;
    //             height: 100%;
    //             padding: 13px 20px;
    //             color: #0085FF;
    //             text-decoration: none;
    //             @include rem(18);
    //             text-align: center;
    //         }
    //     }
    // }
     ul{
        display: flex;
        border-bottom: solid 2px $main-color;

        li{
            width: 100%;
            list-style: none;
            position: relative;
            z-index: 1;
            @include rem(18);

            &::after{
                position: absolute;
                left: 0;
                top: 0;
                z-index: -1;
                width: 100%;
                height: 100%;
                content: "";
                display: block;
                background-color: #fff;
                transform: perspective(0.9em) rotateX(0.5deg);
                transform-origin: left bottom;
                border-radius: 2px 2px 0px 0px;
                // box-shadow: 12px 12px 0px rgb(0 0 0 / 0.05);
                transition: background-color .25s;
            }
            
            a{
                width: 100%;
                height: 50px;
                display: flex;
                align-items: center;
                justify-content: center;
                transition: color .25s;
                text-decoration: none;
                color: #0069FF;
            }

            &:hover{
                a{
                    color: #fff;
                }

                &::after {
                    background-color: $main-color;
                }
            }

            &.current{
                color: #fff;

                &::after {
                    background-color: $main-color;
                }
                
                a{
                    color: #fff;
                }
            }
        }
    }
}

.companies_list{
    margin-top: 40px;
    padding: 40px;
    background-color: #fff;
    box-shadow: 12px 12px 0px rgba(0, 0, 0, 0.05);
    border-radius: 4px;
    
    @include media_query(1100){
        max-width: 760px;
        margin: 0 auto;
    }

    @include media_query(){
        padding: 40px 16px;
    }
}

.companies_list_txt{
    text-align: center;
    @include rem(18);
    font-weight: bold;
    margin-bottom: 24px;

    @include media_query(){
        @include rem(17);
    }
}

.companies_list_inner{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 24px 16px;

    @include media_query(){
        grid-template-columns: 1fr;
        gap: 24px;
    }
}

.company_item{
    padding: 11px 8px 11px 16px;
    border: 1px solid #BDBDBD;
    border-radius: 8px;
    text-decoration: none;
}

.company_item .company_item_tags{
    display: flex;
    flex-wrap: wrap;

    li{
        list-style-type: none;
        padding: 4px 4px 0 0;
    }

    li span{
        color: #0069FF;
        @include rem(12);
        background-color: #fff;
        display: inline-block;
        padding: 2px 12px;
        text-align: center;
        border: 2px solid #0069FF;
        border-radius: 100px;
        font-weight: 700;
        line-height: 24px;

        @include media_query(){
            padding: 4px 12px;
        }
    }
}

.company_item_inner{
    display: grid;
    grid-template-columns: 115px 1fr 40px;
    align-items: center;
    gap: 0 8px;
    color: $font-color;

    @include media_query(){
        grid-template-columns: 115px 1fr 30px;
        padding: 11px 0 11px 0;
    }

    .company_item_img{
        // border: 1px solid #E0E0E0;
        height: 80px;
        padding: 8px;
        max-width: 115px;

        img{
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }

    h2{
        @include rem(18);
        line-height: 1.3;
        
        @include media_query(1100){
            @include rem(16);
        }
    }

    .arrow_wrap{
        display: grid;
        place-content: center;

        .arrow{
            width: 10px;
            height: 18px;
        }
    }
}






.company_single_head{
    display: grid;
    grid-template-columns: 120px 1fr;
    gap: 16px;

    @include media_query(){
        grid-template-columns: 88px 1fr;
    }

    .company_single_logo{
        width: 120px;
        height: 120px;
        //aspect-ratio: 1 / 1;
        background-color: #fff;
        border: 1px solid #CACACA;
        border-radius: 8px;
        overflow: hidden;
        padding: 10px;

        @include media_query(){
            width: 88px;
            height: 88px;
        }

        img{
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }

    .company_single_info{
        h2{
            @include rem(28);
            line-height: 1.3;
            margin-bottom: 20px;

            @include media_query(){
                @include rem(20);
            }
        }

        .company_single_table{
            width: 100%;
            @include media_query(){
                display: none;
            }

            tr{

                th{
                    padding: .3em 0 0 0;
                    text-align: left;
                    font-weight: normal;
                    white-space: nowrap;
                    width: 3.5em;
                }

                td{
                    padding: .3em 0 .3em .3em;
                    line-height: 1.6;

                    &::before{
                        content: ":";
                        margin-right: .3em;
                    }
                }
            }
        }
    }
}

.company_single_table{
    width: 100%;
    @include media_query(){
        display: none;
    }

    tr{

        th{
            padding: .3em 0 0 0;
            text-align: left;
            font-weight: normal;
            white-space: nowrap;
        }

        td{
            padding: .3em 0 .3em .3em;
            line-height: 1.6;

            &::before{
                content: ":";
                margin-right: .3em;
            }

            span{
                & + span{
                    &::before{
                        content: "、";
                    }
                }
            }
        }
    }
}

.company_single_table_sp{
    display: none;
    width: 100%;
    margin-top: 8px;

    @include media_query(){
        display: table;
    }

    tr{

        td{
            padding: .1em 0 .1em .3em;
            padding-left: 1.3em;
            text-indent: -0.5em;
        }
    }
}

.company_feature{
    margin-top: 24px;
    width: 100%;
    display: grid;
    grid-template-columns: 76px 1fr;
    align-items: center;
    gap: 24px;
    background-color: #0085FF;
    padding: 16px 45px;
    border-radius: 100px;

    @include media_query(){
        margin-top: 16px;
        border-radius: 90px;
        grid-template-columns: 1fr;
        gap: 8px;
    }

    &::before{
        display: block;
        content: "";
        aspect-ratio: 76 / 63;
        background-image: url(../svg/img-point_pc.svg);
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;

        @include media_query(){
            width: 100%;
            height: 28px;
            aspect-ratio: auto;
            background-image: url(../svg/img-point_sp.svg);
            background-size: 152px auto;
        }
    }

    p{
        color: #fff;
        line-height: 1.6;
        
        span{
            & + span{
                &::before{
                    content: "、"
                }
            }
        }
    }
}

.company_single_contents{
    margin-top: 40px;
    padding: 40px;
    background-color: #fff;
    box-shadow: 12px 12px 0px rgba(0, 0, 0, 0.05);
    border-radius: 4px;

    @include media_query(){
        margin-top: 16px;
        padding: 40px 24px;
    }
}

.company_single_contents_inner{
    padding-bottom: 40px;
    border-bottom: 1px solid #C4C4C4;

    &:last-of-type{
        border-bottom: none;
        padding-bottom: 0;
    }

    h3{
        margin-bottom: 24px;
        @include rem(24);
        line-height: 1.3;
        color: #0169EA;
        text-align: center;
    }

    p{
        line-height: 1.6;
    }

    & + .company_single_contents_inner{
        margin-top: 40px;
    }
}

.link_item_wrap{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 16px 24px;

    @include media_query(){
        grid-template-columns: 1fr;
    }

    .link_item{
        display: grid;
        grid-template-columns: 1fr 6px;
        align-items: center;
        padding: 16px;
        border: 1px solid #C4C4C4;
        border-radius: 4px;
        line-height: 1.6;
        color: $font-color;
        text-decoration: none;

        &::after{
            display: block;
            content: "";
            width: 6px;
            height: 11px;
            background-image: url(../svg/arrow_right.svg);
            background-size: contain;
            background-repeat: no-repeat;
        }
    }
}

.company_single_tags{
    width: 100%;

    dl{
        .company_single_tags_inner{
            display: grid;
            grid-template-columns: 150px 1fr;

            @include media_query(){
                grid-template-columns: 1fr;
            }

            & + .company_single_tags_inner{
                margin-top: 24px;

                @include media_query(){
                    margin-top: 40px;
                }
            }

            dt{
                display: flex;
                align-items: center;
                border-right: solid 1px #C4C4C4;
                font-weight: bold;
                @include rem(18);

                @include media_query(){
                    justify-content: center;
                    border: none;
                    margin-bottom: 16px;
                }
            }

            dd{
                display: grid;
                grid-template-columns: repeat(4, 1fr);
                gap: 16px 0;

                @include media_query(){
                    grid-template-columns: 1fr;
                    gap: 0;
                }

                span{
                    display: block;
                    padding: .3em 20px;
                    line-height: 1.3;

                    @include media_query(){
                        border-bottom: 1px dashed #C4C4C4;
                        padding: 8px;
                    }

                    & + span{
                        border-left: solid 1px #C4C4C4;

                        @include media_query(){
                            border-left: none;
                        }
                    }

                    &:nth-child(4n+1){
                        border-left: none;
                    }
                }
            }
        }
    }
}




.search_area{
    margin-top: 48px;
    
    .search_head{
        margin-top: 20px;
    }

    .search_contents{
        margin-top: 20px;
    }
}
