@charset "UTF-8";

//*------------------------------------*//
//	Base
//*------------------------------------*//

@use 'base/reset';
@use 'base/default';






//*------------------------------------*//
//	page
//*------------------------------------*//
@use 'page/common';
@use 'page/index';
@use 'page/page';
@use 'page/timetable';
@use 'page/campaign';
@use 'page/company';






//*------------------------------------*//
//	Fonts
//*------------------------------------*//
// @import url('https: //fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;500;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;500;700&display=swap');