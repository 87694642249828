@use '../base/vars' as *;

.page{
    background-color: #EBFEFF;
    background-image: url(../svg/page_head.svg), url(../svg/page_foot.svg);
    background-repeat: no-repeat;
    background-position: center top, center bottom;
    background-size: 100% auto;

    @include media_query(){
        background-image: url(../svg/page_head_sp.svg), url(../svg/page_foot_sp.svg);
        background-position: center top, center bottom 70px;
    }

    // .navigation{
    //     margin-left: 160px;

    //     @include media_query(1200){
    //         margin-left: 0;
    //     }
    // }

    .main{
        width: 100%;
        padding: 180px 0 170px;

        @include media_query(){
            padding: 165px 15px 75px;
        }
    }
}






.btn_retrun{
    display: flex;
    justify-content: center;
    margin-top: 50px;
    gap: 50px;

    a{
        display: flex;
        align-items: center;
        color: #2F80ED;

        &:hover{
            opacity: .7;
        }

        &::before{
            content: "";
            width: 6px;
            height: 11px;
            margin-right: 5px;
            background-image: url(../svg/arrow_left.svg);
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
        }
    }
}




.others{
    margin-top: 50px;

    h3{
        display: flex;
        justify-content: center;
        align-items: flex-end;
        gap: 20px;
        @include rem(28);
        line-height: 1.4;

        @include media_query(){
            @include rem(19);
        }

        &::before,
        &::after{
            display: block;
            content: "";
            width: 3px;
            height: 40px;
            background-color: $font-color;

            @include media_query(){
                width: 2px;
                height: 25px;
            }
        }

        &::before{
            transform: rotate(-30deg);
        }

        &::after{
            transform: rotate(30deg);
        }
    }
}







.timetable{
    max-width: 800px;
    margin: 0 auto;
}
