@use '../base/vars' as *;

.timetable_contents{
    margin-top: 40px;
    padding: 40px 16px;
    background-color: #fff;
    // box-shadow: 12px 12px 0px rgba(0, 0, 0, 0.05);

    @include media_query(){
        // box-shadow: 6px 6px 0px rgba(0, 0, 0, 0.05);
    }

    .timetable_contents_text{
        text-align: center;
        color: #334156;
        @include rem(16);
        margin: 24px 0;
        font-weight: normal;
    }

    h3{
        margin-bottom: 24px;
        font-family: 'Futura';
        @include rem(24);
        line-height: 1.4;
        font-weight: bold;
        letter-spacing: 0.01em;
        text-align: center;

        span{
            @include rem(14);
            font-weight: normal;
        }
    }

    .timetable_area{
        max-width: 460px;
        margin: 0 auto;

        @include media_query(){
            //display: block;
        }

        tr{

            &:last-child{
                th,td{
                    padding-bottom: 0;
                    border-bottom: none;
                }
            }

            &.pc_tr{
                th,td{
                    //padding-bottom: 0;
                    //border-bottom: none;
                }

                .session{
                    display: none;
                    @include media_query(){
                        display: none;
                    }
                }
            }

            &.sp_tr{
                display: none;

                td{
                    padding: 0 0 16px 0;
                }

                @include media_query(){
                    //display: table-row;
                }
            }
        }

        th{
            white-space: nowrap;
            padding: 8px 40px 8px 0;
            text-align: left;

            @include media_query(){
                text-align: left;
                padding: 12px 16px 12px 0;
                border-bottom: dotted 1px #CECECE;
            }

            &.tar{
                text-align: right;
            }
        }

        td{
            font-weight: 400;
            padding: 8px 0;

            @include media_query(){
                padding: 12px 16px 12px 0;
                border-bottom: dotted 1px #CECECE;
                line-height: 1.53;
            }
        }
    }

    .session{
        margin-top: 8px;
        padding: 16px;
        border: solid 1px #CECECE;
        border-radius: 4px;
        text-align: center;

        .session_logo{
            margin-bottom: 5px;

            img{
                width: 100%;
                max-width: 270px;
                height: auto;
            }

            &.logo_hitachi,
            &.nologoimg2{
                img{
                    max-width: 100px;
                }
            }
        }

        h4{
            @include rem(13);
            line-height: 1.5;
        }
    }

    .information{
        margin-top: 8px;
        background-color: #EFEFEF;
        padding: 16px;
        @include rem(14);
        font-weight: 400;
        line-height: 1.43;
    }
}
