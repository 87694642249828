@use '../base/vars' as *;




.firstview{
    width: 100%;
    // aspect-ratio: 1366 / 1184;
    background-image: url(../svg/top_connection.svg), url(../svg/firstview_bg.svg);
    background-repeat: no-repeat;
    // background-position: bottom right;
    background-position: bottom -1px center, top center;
    background-size: contain;
    background-size: 100% auto;
    padding-bottom: 180px;
    
    @include media_query(1366){
        aspect-ratio: 1366 / 1184;
        padding-bottom: 0;
    }

    @include media_query(){
        // aspect-ratio: 375 / 1075;
        background-image: url(../svg/top_connection_sp.svg), url(../svg/firstview_bg_sp.svg);
        background-position: bottom -1px center, top center;
        background-size: 100% auto;
        padding-bottom: 60px;
    }

    .firstview_inner{
        padding: 0 8px;

        .firstview_head{
            max-width: 1250px;
            margin: 0 auto;
            padding: 12% 0 0 0;
            
            @include media_query(1180){
                padding: 120px 0 0 20px;
            }
            
            @include media_query(){
                padding: 150px 0 0 0;
            }

            .firstview_head_inner{
                width: 100%;
                height: 566px;
                // padding-top: 20px;
                background-image: url(../img/img-mainvisual_pc.webp);
                background-repeat: no-repeat;
                background-position: right bottom;
                background-size: 443px auto;
                
                @include media_query(1180){
                    background-size: 340px auto;
                }
                
                @include media_query(1100){
                    max-width: 750px;
                    height: 500px;
                    margin: 0 auto;
                    background-position: right top;
                    background-size: 244px auto;
                }

                @include media_query(){
                    height: 500px;
                    padding: 0;
                    background-image: url(../img/img-mainvisual_sp.webp);
                    background-position: center bottom -5px;
                    background-size: 331px auto;
                }
            }

            img{
                width: 100%;
                // max-width: 791px;
                max-width: 670px;
                height: auto;
                
                @include media_query(1180){
                    max-width: 355px;
                }
                
                @include media_query(1100){
                    max-width: 474px;
                }

                @include media_query(){
                    display: block;
                    // max-width: 355px;
                    max-width: 100%;
                    // margin: 0 auto;
                }
            }
            
            .firstview_head_date{
                margin-top: 33px;
                display: flex;
                align-items: flex-end;
                gap: 10px;
                color: #334156;
                
                @include media_query(){
                    margin-top: 24px;
                }
                
                @include media_query(){
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                }
                
                p{
                    @include rem(24);
                    font-weight: bold;
                    
                    @include media_query(1100){
                        @include rem(18);
                    }
                    
                    @include media_query(){
                        @include rem(16);
                    }
                    
                    span{
                        @include rem(36);
                        
                        @include media_query(1100){
                            @include rem(28);
                        }
                        
                        @include media_query(){
                            @include rem(24);
                        }
                    }
                }
                
                small{
                    font-weight: normal;
                    @include rem(12);
                    
                    @include media_query(1100){
                        @include rem(10);
                    }
                }
                
                // ul{
                //     li{
                //         display: flex;
                //         align-items: center;
                //         gap: 18px;
                        
                //         & + li{
                //             margin-top: 12px;
                //         }
                //     }
                // }
                // img{
                //     max-width: 503px;
                //     height: auto;
                // }
                
                // .small_note{
                //     margin-top: 12px;
                //     @include rem(12);
                //     font-weight: normal;
                // }
            }

            .btn_application{
                flex: 1;
                margin: 32px 0 0;
                
                @include media_query(){
                    margin: 24px 0 0;
                    // display: flex;
                    // justify-content: flex-end;
                }

                a{
                    width: 100%;
                    max-width: 336px;
                    height: 78px;
                    @include rem(32);
                    font-weight: 700;
                    margin-top: 32px;

                    @include media_query(){
                        max-width: 300px;
                        height: 50px;
                        margin: 0 auto;
                        @include rem(16);
                    }
                }
            }
        }
    }
}

.participant{
    max-width: 1250px;
    margin: -100px auto 0;
    padding: 40px 80px;
    background-color: #EDFDFF;
    box-shadow: 12px 12px 0px rgba(0, 0, 0, 0.15);
    border-radius: 16px;
    background-image: url(../svg/section-concept.svg);

    @include media_query(1100){
        max-width: 760px;
    }

    @include media_query(){
        padding: 20px 16px;
        margin-top: -70px;
    }

    h2{
        @include rem(28);
        font-weight: 700;
        line-height: 1.43;
        gap: 20px;

        display: flex;
        align-items: flex-end;
        justify-content: center;
        
        @include media_query(1100){
            @include rem(34);
        }
        
        @include media_query(){
            gap: 10px;
            @include rem(18);
        }

        &::before,
        &::after{
            display: block;
            content: "";
            width: 3px;
            height: 40px;
            background-color: $font-color;

            @include media_query(){
                width: 2px;
                height: 20px;
            }
        }

        &::before{
            transform: rotate(-45deg);
        }

        &::after{
            transform: rotate(45deg);
        }
    }

    .participant_inner{
        display: grid;
        grid-template-columns: 1fr 344px;
        gap: 80px;
        margin-top: 30px;
        
        @include media_query(1100){
            grid-template-columns: 1fr;
        }
        
        @include media_query(){
            margin-top: 20px;
            grid-template-columns: 1fr;
            gap: 16px;
        }
        
        p{
            color: #334156;
            @include rem(20);
            font-weight: 500;
            line-height: 2.2;
            
            @include media_query(){
                @include rem(14);
                line-height: 1.8;
            }
        }
        
        img{
            width: 100%;
            max-width: 344px;
            height: auto;
            
            
            @include media_query(1100){
                margin: 0 auto;
            }
            @include media_query(){
                max-width: 200px;
                margin: 0 auto;
            }
        }

        // ul{
        //     display: grid;
        //     grid-template-columns: repeat(7, 1fr);
        //     // gap: 10px;
        //     // max-width: 1010px;
        //     margin: 0 auto;

        //     @include media_query(){
        //         gap: 4px;
        //         grid-template-columns: repeat(5, 1fr);
        //         gap: 0;
        //     }

        //     li{
        //         list-style: none;
        //         padding: 2px 3px;

        //         img{
        //             width: 100%;
        //             height: 100%;
        //             aspect-ratio: 7 / 3;
        //             object-fit: contain;
        //         }
        //     }
        // }
    }
}


.btn_company_link{
    margin-top: 40px;
    text-align: center;
    
    a{
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 700;
        @include rem(16);
        line-height: 23px;
        color: #2F80ED;
        transition: opacity .25s;
        
        @include media_query(){
            @include rem(14);
        }
        
        &:hover{
            opacity: .7;
        }
        
        &::after{
            display: block;
            content: "";
            width: 6px;
            height: 11px;
            background-image: url(../svg/arrow_right.svg);
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
            margin-left: 10px;
        }
    }
}





.section_title{
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    @include rem(48);
    font-weight: 900;
    line-height: 70px;

    @include media_query(){
        @include rem(40);
    }

    span{
        position: relative;
        z-index: 3;
        @include rem(20);
        line-height: 1;
        background-color: #E6FCFF;
        padding: 0 8px;

        @include media_query(){
            @include rem(16);
        }
    }

    &::after {
        position: absolute;
        bottom: 5px;
        z-index: 1;
        content: "";
        display: block;
        width: 190px;
        height: 1px;
        background-color: $font-color;
    }

}





.concept{
    background-image: url(../img/dot.png);

    &::after {
        display: block;
        content: "";
        width: 100%;
        aspect-ratio: 1366 / 200;
        background-image: url(../img/concept-feature.png);
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center top;

        @include media_query() {
            display: none;
        }
    }

    .concept_inner{
        max-width: 1020px;
        margin: 0 auto;
        padding: 60px 20px;

        @include media_query(){
            padding: 40px 16px;
        }
    }

    .concept_main{
        display: grid;
        grid-template-columns: 58.125% 37.916%;
        gap: 3.959%;

        @include media_query(){
            grid-template-columns: 1fr;
            grid-template-rows: auto auto;
            gap: 25px;
        }

        .concept_text{
            padding-top: 50px;

            h3{
                @include rem(32);
                font-weight: 700;
                line-height: 1.34;

                @include media_query(){
                    @include rem(20);
                    max-width: 300px;
                    margin: 0 auto;
                    text-align: center;
                }
            }

            p{
                margin-top: 20px;
                @include rem(16);
                line-height: 1.75;

                @include media_query(){
                    @include rem(14);
                }
                
                small{
                    color: #828282;
                    @include rem(12);
                }
            }
        }

        .concept_img{
            @include media_query(){
                text-align: center;
            }

            img{
                width: 100%;
                height: auto;

                @include media_query(){
                    max-width: 300px;
                }
            }
        }
    }
}




.sp_application{
    display: none;
    background-image: url(../img/sp_application_bg.png);
    background-repeat: no-repeat;
    background-size: 100% auto;
    margin-top: -30px;

    &::after {
        position: relative;
        bottom: -3px;
        display: block;
        content: "";
        width: 100%;
        height: 50px;
        //opacity: .7;
        //background-color: #f00;
        aspect-ratio: 375 / 50;
        background-image: url(../img/concept-feature_sp.png);
        background-size: 100% auto;
        background-repeat: no-repeat;
        background-position: center top;
    }

    @include media_query(){
        display: block;
    }

    .sp_application_inner{
        padding: 40px 16px 30px;

        h2{
            @include rem(20);
            font-weight: 900;
            color: #fff;
            text-align: center;
            line-height: 1.43;
        }
        
        .application_btns{
        // max-width: 830px;
        // margin: 0 auto;
        margin-top: 25px;
        display: flex;
        justify-content: center;
        gap: 40px;
        
        @include media_query(){
            display: block;
        }
    }
    
    .bnt_application{
        flex: 1;
        max-width: 350px;
        // margin-top: 25px;
        
        @include media_query(){
            width: 100%;
            margin: 0 auto;
            
            & + .bnt_application{
                margin-top: 12px;
            }
        }
        

        a{
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 75px;
            // margin: 0 auto;
            @include rem(24);
            font-weight: bold;
            text-decoration: none;
            color: #fff;
            background: linear-gradient(93.29deg, #FF7A00 0%, #FFA800 102.53%);
            box-shadow: 6px 6px 0px rgba(0, 0, 0, 0.1);
            border-radius: 4px;
            transition: opacity .25s;

            &:hover {
                opacity: .7;
            }
            
            span{
                @include rem(21);
            }

            @include media_query() {
                // max-width: 300px;
                width: 100%;
                height: 50px;
                @include rem(20);
                //box-shadow: none;
                box-shadow: 3px 3px 0px rgba(0, 0, 0, 0.1);
                
                span{
                    @include rem(18);
                }
            }
        }
    }

        .btn_application{
            margin: 15px 0 0 0;

            a{
                width: 100%;
                max-width: 300px;
                height: 50px;
                margin: 0 auto;
                @include rem(18);
                font-weight: bold;
            }
        }
    }
}





.feature{
    margin-top: -1px;
    background-color: #fff;
    position: relative;
    overflow: hidden;

    .feature_inner{
        padding: 30px 0;

        @include media_query(){
            padding: 40px 0;
        }

        .section_title{
            span{
                background-color: #fff;
            }
        }
    }




    .decoration01{
        top: 20px;
        right: 100px;
    }
    .decoration02{
        top: 150px;
        right: 80px;
    }
    .decoration03{
        top: 450px;
        left: 80px;
    }
    .decoration04{
        bottom: 10px;
        right: 80px;
        transform: rotate(90deg);
    }
}

.feature_main{
    max-width: 960px;
    margin: 40px auto 0;
    
    @include media_query(1100){
        max-width: 760px;
    }

    @include media_query(850){
        padding: 0 16px;
    }
}

.feature_item{
    display: flex;
    align-items: center;
    gap: 10px;
    background: #EDFDFF;
    box-shadow: 12px 12px 0px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    padding: 24px 40px;

    @include media_query(){
        flex-direction: column;
        box-shadow: 6px 6px 0px rgba(0, 0, 0, 0.05);
        padding: 16px 16px 28px;
    }

    &:nth-child(odd){
        .feature_item_img{
            order: 1;

            @include media_query(){
                order: 0;
            }
        }
    }

    & + .feature_item{
        margin-top: 20px;
    }

    .feature_item_text{
        display: flex;
        align-items: center;

        .point{
            margin-right: 30px;

            @include media_query(){
                display: none;
            }
        }

        .feature_item_contents{
            h3{
                @include rem(20);
                line-height: 1.45;
                letter-spacing: -.02em;
                margin-bottom: 12px;

                @include media_query(){
                    @include rem(18);
                }
            }

            p{
                @include rem(16);
                line-height: 1.4;

                @include media_query(){
                    @include rem(14);
                }
            }
        }
    }

    .feature_item_img{
        max-width: 280px;

        @include media_query(){
            max-width: 315px;
        }

        img{
            width: 100%;
            height: auto;
        }
    }
}





.campaign_wrap{
    padding: 0 16px;
    
    .section_title{
        margin: 100px 0 40px;
    }
}

.campaign{
    max-width: 960px;
    margin: 25px auto 0;
    padding: 32px 24px;
    border-radius: 8px;
    background: linear-gradient(272.22deg, #83F0FF -2.53%, #27A8E7 100.99%);
    box-shadow: 12px 12px 0px rgba(0, 0, 0, 0.05);
    
    @include media_query(1100){
        max-width: 760px;
    }

    @include media_query(){
        padding: 25px 16px;
        box-shadow: 6px 6px 0px rgba(0, 0, 0, 0.05);
    }

    .campaign_inner{
        h3{
            display: grid;
            grid-template-columns: 1fr 480px 1fr;
            align-items: center;
            gap: 20px;
            text-align: center;
            color: #fff;
            margin-bottom: 20px;

            @include media_query(){
                display: block;
                padding: 8px 0;
                border-top: dotted 3px #fff;
                border-bottom: dotted 3px #fff;
            }
            
            br{
                display: none;
                
                @include media_query(1100){
                    display: block;
                }
            }

            span{
                @include rem(28);

                @include media_query(){
                    @include rem(18);
                }
            }

            &::before,
            &::after{
                content: "";
                display: block;
                border-bottom: dotted 3px #fff;

                @include media_query(){
                    display: none;
                }
            }
        }
    }

    .benefits{
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: auto auto;
        gap: 16px;
        grid-template-areas:
            "benefits01 benefits02"
            "benefits03 benefits03";

        @include media_query(){
            grid-template-columns: 1fr;
            grid-template-rows: repeat(3, auto);
            grid-template-areas:
                "benefits01"
                "benefits02"
                "benefits03";
        }
    }

    .benefits_item{
        background-color: #fff;
        border-radius: 4px;
        padding: 18px;
    }

    .benefits00{
        display: grid;
        grid-template-columns: 2fr 1fr;
        gap: 24px;

        @include media_query(){
            grid-template-columns: 1fr;
            grid-template-rows: auto;
            gap: 16px;
        }

        h4{
            @include rem(24);
            line-height: 1.4;
            color: #0085FF;
            margin-bottom: 10px;

            @include media_query(){
                @include rem(18);
                padding: 0;
                text-align: center;
            }
        }

        p{
            @include rem(16);
            line-height: 1.4;
        }

        .benefits00_inner_btns{
            display: grid;
            place-content: center;

            .bnt_benefits00{
                a{
                    max-width: 256px;
                    height: 80px;
                    margin: 0 auto;
                    display: grid;
                    place-content: center;
                    background: linear-gradient(93.29deg, #FF7A00 0%, #FFA800 102.53%);
                    box-shadow: 3px 3px 0px rgba(0, 0, 0, 0.1);
                    border-radius: 4px;
                    color: #fff;
                    @include rem(24);
                    font-weight: bold;
                    text-decoration: none;

                    @include media_query(){
                        max-width: 100%;
                        height: 52px;
                        @include rem(18);
                    }
                }
            }

            p{
                margin-top: 10px;
                @include rem(16);
                font-weight: bold;
            }
        }
    }

    .benefits01,
    .benefits02{
        text-align: center;

        h4 {
            @include rem(24);
            line-height: 1.4;
            color: #0085FF;
            margin-bottom: 16px;

            @include media_query(){
                @include rem(18);
                padding: 0;
            }
        }

        p{
            @include rem(22);
            line-height: 1.4;
            font-weight: bold;

            @include media_query(){
                @include rem(16);
            }
        }

        img {
            width: 100%;
            height: auto;
            margin-top: 20px;
        }

        .benefits_small {
            margin-top: 20px;
            text-align: right;
            @include rem(12);
            line-height: 2;
            color: #777;
        }
    }

    .benefits00{
        grid-area:benefits00;
    }

    .benefits01{
        grid-area:benefits01;
        // background-image: url(../img/benefits01_title.png);
        // background-repeat: no-repeat;
        // background-size: 98px 80px;
        // background-position: left 15px top 15px;

        img{
            max-width: 231px;
        }

        @include media_query(){
            background-image: none;
        }
    }

    .benefits02{
        grid-area: benefits02;
        // background-image: url(../img/benefits02_title.png);
        // background-repeat: no-repeat;
        // background-size: 98px 80px;
        // background-position: left 15px top 15px;

        img{
            max-width: 225px;
        }

        @include media_query() {
            background-image: none;
        }
    }

    .benefits03{
        grid-area: benefits03;

        display: grid;
        grid-template-columns: 289px 1fr;
        gap: 20px;
        

        @include media_query(1100){
            align-items: center;
        }

        @include media_query(){
            grid-template-columns: 1fr;
        }

        img{
            width: 100%;
            height: auto;
        }

        .benefits03_text{
            @include media_query(){
                order: -1;
            }
        }

        h4{
            @include rem(24);
            color: $main-color;
            line-height: 1.45;
            margin-bottom: 10px;

            @include media_query(){
                @include rem(22);
                text-align: center;
            }
            
            br{
                display: none;
                
                @include media_query(1100){
                    display: block;
                }
            }
        }

        p{
            @include rem(16);
            line-height: 1.43;
        }
    }
}

.btn_detail{
    margin-top: 16px;

    a{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        max-width: 360px;
        height: 48px;
        margin: 0 auto;
        background-color: #fff;
        box-shadow: 6px 6px 0px rgba(0, 0, 0, 0.1);
        border-radius: 4px;
        text-decoration: none;
        color: $font-color;
        transition: opacity .25s;
        font-weight: bold;

        &:hover {
            opacity: .7;
        }

        @include media_query(){
            max-width: 100%;
            @include rem(18);
        }

        span{
            display: flex;
            align-items: center;

            &::after{
                content: "";
                display: block;
                width: 24px;
                height: 24px;
                background-image: url(../img/icon_link.png);
                background-size: contain;
            }
        }
    }
}






.sp_feature-application-search {
    position: relative;
    z-index: 3;
    display: none;
    background-image: url(../img/sp_feature-application-search_bg.png);
    background-position: bottom center;
    background-size: 100% auto;

    &::before {
        position: relative;
        top: -1px;
        display: block;
        content: "";
        width: 100%;
        height: 50px;
        aspect-ratio: 375 / 50;
        background-image: url(../img/feature-search_sp.png);
        background-size: 100% auto;
        background-repeat: no-repeat;
        background-position: center top;
    }

    @include media_query() {
        display: block;
    }

    .sp_application_inner {
        padding: 30px 16px 60px;

        h2 {
            @include rem(20);
            font-weight: 900;
            color: #fff;
            text-align: center;
            line-height: 1.43;
        }
        
        .application_btns{
        // max-width: 830px;
        // margin: 0 auto;
        margin-top: 25px;
        display: flex;
        justify-content: center;
        gap: 40px;
        
        @include media_query(){
            display: block;
        }
    }
    
    // .bnt_application{
    //     flex: 1;
    //     max-width: 350px;
    //     // margin-top: 25px;
        
    //     @include media_query(){
    //         width: 100%;
    //         margin: 0 auto;
            
    //         & + .bnt_application{
    //             margin-top: 12px;
    //         }
    //     }
        

    //     a{
    //         display: flex;
    //         justify-content: center;
    //         align-items: center;
    //         width: 100%;
    //         height: 75px;
    //         // margin: 0 auto;
    //         @include rem(24);
    //         font-weight: bold;
    //         text-decoration: none;
    //         color: #fff;
    //         background: linear-gradient(93.29deg, #FF7A00 0%, #FFA800 102.53%);
    //         box-shadow: 6px 6px 0px rgba(0, 0, 0, 0.1);
    //         border-radius: 4px;
    //         transition: opacity .25s;

    //         &:hover {
    //             opacity: .7;
    //         }
            
    //         span{
    //             @include rem(21);
    //         }

    //         @include media_query() {
    //             // max-width: 300px;
    //             width: 100%;
    //             height: 50px;
    //             @include rem(20);
    //             //box-shadow: none;
    //             box-shadow: 3px 3px 0px rgba(0, 0, 0, 0.1);
                
    //             span{
    //                 @include rem(18);
    //             }
    //         }
    //     }
    // }

        .btn_application {
            margin: 15px 0 0 0;

            a {
                width: 100%;
                max-width: 300px;
                height: 50px;
                margin: 0 auto;
                @include rem(18);
                font-weight: bold;
            }
        }
    }
}





.search{
    position: relative;
    overflow: hidden;
    background-image: url(../img/dot.png);

    @include media_query(){
        margin-top: -35px;
        padding: 50px 0 0;
    }

    &::before {
        display: block;
        content: "";
        width: 100%;
        aspect-ratio: 1366 / 150;
        background-image: url(../img/feature-search.png);
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center top;

        @include media_query() {
            display: none;
        }
    }

    &::after {
        display: block;
        content: "";
        width: 100%;
        height: 80px;
        background-image: url(../svg/top_connection.svg);
        background-size: 100% auto;
        background-repeat: no-repeat;
        background-position: bottom center;
    }

    .decoration01 {
        top: 1000px;
        left: 100px;
    }

    .decoration02 {
        top: 1200px;
        right: 80px;
    }
}

.search_inner{
    max-width: 925px;
    margin: 0 auto;
    // padding: 20px 16px 0;
    padding: 10px 16px 50px;

    @include media_query(1100){
        max-width: 760px;
    }

    @include media_query(){
        // padding: 70px 16px 30px;
    }
    
    & + .search_inner{
        border-top: solid 1px #000;
        // margin-top: 50px;
    }
    
    .date{
        margin-top: 40px;
        text-align: center;
        @include rem(21);
        font-weight: bold;
        
        span{
            @include rem(32);
        }
    }

    .search_head{
        margin-top: 40px;
        text-align: center;

        p{
            @include rem(20);
            font-weight: 700;
            line-height: 1.45;

            small{
                @include rem(12);
                font-weight: normal;
            }
        }
    }
}

.search_contents{
    // margin-top: 40px;
    margin-top: 20px;
}


.tab{

    @include media_query(){
        display: none;
    }

    ul{
        display: flex;
        border-bottom: solid 2px $main-color;

        li{
            list-style: none;
            position: relative;
            width: 100%;
            height: 50px;
            z-index: 1;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            @include rem(18);
            transition: color .25s;

            &::after{
                position: absolute;
                left: 0;
                top: 0;
                z-index: -1;
                width: 100%;
                height: 100%;
                content: "";
                display: block;
                background-color: #fff;
                transform: perspective(0.5em) rotateX(0.5deg);
                transform-origin: left bottom;
                border-radius: 2px 2px 0px 0px;
                box-shadow: 12px 12px 0px rgb(0 0 0 / 0.05);
                transition: background-color .25s;
            }

            &:hover{
                color: #fff;

                &::after {
                    background-color: $main-color;
                }
            }

            &.current{
                color: #fff;

                &::after {
                    background-color: $main-color;
                }
            }
        }
    }
}




.sp_accordion{
    display: none;

    .accordion{
        border-bottom: solid 1px #CECECE;

        button[aria-expanded=true] {
            .accordion_header {
                &::after {
                    content: "";
                    transform: rotate(135deg);
                }
            }
        }
    }

    .accordion_wrap{

        .accordion_header{
            width: 100%;
            display: flex;
            align-items: center;
            @include rem(16);
            font-weight: 700;
            padding: 18px;

            &::after{
                content: "";
                display: block;
                width: 15px;
                height: 15px;
                border: 2px solid;
                border-color: transparent transparent $main-color $main-color;
                transform: rotate(-45deg);
                margin: 0 0 0 auto;
                transition: transform .25s;
            }
        }

        .accordion_body{
            //display: none;
            overflow: hidden;
            background-color: #F2F2F2;
            transition: height .25s;


            ul{
                display: flex;
                flex-wrap: wrap;
                gap: 10px;
                padding: 10px 15px 24px;

                li{
                    list-style: none;
                    background: #FFFFFF;
                    border-radius: 4px;
                    padding: 10px 12px;
                    color: $main-color;

                    &.selected{
                        background-color: $main-color;
                        color: #fff;
                    }
                }
            }
        }
    }

    @include media_query(){
        display: block;
    }
}




.search_main{
    position: relative;
    z-index: 2;
    padding: 40px 25px 25px;
    background-color: #fff;
    box-shadow: 13px 10px 0px rgba(0, 0, 0, 0.05);
    border-radius: 0px 0px 8px 8px;

    @include media_query(){
        border-radius: 8px;
        padding: 0 0 25px;
    }
}




.selected_tags_wrap{
    @include media_query(){
        padding: 0 16px;
    }
}



.search_tags{
    width: 100%;
    height: 212px;
    overflow: scroll;

    @include media_query(){
        display: none;
    }

    &.hidden{
        display: none;
    }

    ul{
        display: flex;
        flex-wrap: wrap;
        gap: 8px;

        li{
            list-style: none;
            background-color: #F2F2F2;
            border-radius: 4px;
            @include rem(16);
            color: $main-color;
            line-height: 1.43;
            padding: 4px 12px;
            cursor: pointer;

            transition: color .25s, background-color .25s;

            &::before{
                content: "#";
            }

            &:hover{
                background-color: $main-color;
                color: #fff;
            }

            &.selected{
                background-color: $main-color;
                color: #fff;
            }
        }
    }
}



.selected_tags{
    background: #F2F2F2;
    border: 1px solid #CECECE;
    border-radius: 8px;
    padding: 10px 25px;
    margin-top: 30px;

    @include media_query(){
        padding: 16px;
        margin-top: 22px;
    }

    h3{
        text-align: center;
        color: #777;
        font-weight: 500;
        @include rem(14);
        margin-bottom: 20px;
    }

    ul{
        display: flex;
        flex-wrap: wrap;
        gap: 10px 24px;

        @include media_query(){
            flex-direction: column;
        }

        li{
            display: flex;
            align-items: center;
            gap: 10px;
            list-style: none;
            @include rem(16);
            line-height: 1.43;
            padding-right: 24px;
            border-right: solid 1px #CECECE;

            @include media_query(){
                @include rem(14);
                border: none;
            }

            .del{
                display: block;
                width: 16px;
                min-width: 16px;
                height: 16px;
                background-image: url(../img/icon_del.png);
                background-repeat: no-repeat;
                background-size: contain;
                cursor: pointer;
                transition: opacity .25s;

                &:hover{
                    opacity: .7;
                }
            }
        }
    }

    .selected_count{
        text-align: center;
        color: #777;
        font-weight: 500;
        @include rem(14);
        line-height: 1.43;
        margin-top: 16px;
    }
}




.search_contents_foot{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    margin-top: 15px;

    @include media_query(){
        flex-direction: column;
    }

    p{
        @include rem(18);
        font-weight: 700;
        line-height: 1.43;

        span{
            @include rem(36);
            color: $main-color;
            padding: 0 .1em;
        }
    }

    .btn_search{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        max-width: 228px;
        height: 54px;
        background: linear-gradient(93.36deg, #0085FF 0%, #72E3FB 111.9%);
        box-shadow: 6px 6px 0px rgba(0, 0, 0, 0.1);
        border-radius: 4px;
        @include rem(18);
        font-weight: 700;
        text-decoration: none;
        line-height: 1.43;
        color: #fff;
        transition: opacity .25s;

        &:hover {
            opacity: .7;
        }
    }
}





.search_result{
    margin-top: 60px;

    h3{
        display: flex;
        justify-content: center;
        align-items: flex-end;
        gap: 20px;
        @include rem(28);
        line-height: 1.4;

        @include media_query(){
            @include rem(18);
        }

        &::before,
        &::after{
            display: block;
            content: "";
            width: 3px;
            height: 40px;
            background-color: $font-color;

            @include media_query(){
                width: 2px;
                height: 25px;
            }
        }

        &::before{
            transform: rotate(-30deg);
        }

        &::after{
            transform: rotate(30deg);
        }
    }

    .search_result_inner{
        display: flex;
        gap: 16px;
        overflow: scroll;
        margin-top: 25px;
        padding-bottom: 10px;

        @include media_query(){
            max-height: 465px;
            flex-direction: column;
            padding: 0 6px 6px 6px;
        }
    }

    .search_result_item{
        max-width: 370px;
        min-width: 370px;
        flex: 1;
        background: #fff;
        border-radius: 8px;
        padding: 20px 25px;
        box-shadow: 8px 8px 0px rgb(0 0 0 / 0.05);

        text-decoration: none;
        color: $font-color;

        &:hover{
            opacity: .7;
        }

        @include media_query(){
            max-width: 100%;
            min-width: 100%;
            box-shadow: 6px 6px 0px rgb(0 0 0 / 0.05);
        }

        .search_result_item_head{
            display: grid;
            grid-template-columns: 80px 1fr;
            gap: 10px;

            .corporate_logo{
                width: 80px;
                height: 80px;
                border: solid 1px #f2f2f2;
                padding: 10px;

                img{
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }
            }

            h4{
                @include rem(20);
                line-height: 1.43;
            }
        }

        .search_result_item_main{
            margin-top: 10px;

            table{
                tr{
                    th{
                        white-space: nowrap;
                        @include rem(14);
                        line-height: 1.43;
                        font-weight: 400;
                        padding: 2px 5px 2px 0;

                        &::after{
                            content: "：";
                        }
                    }

                    td{
                        @include rem(14);
                        line-height: 1.43;
                        font-weight: 400;
                        padding: 2px 0;

                        span{
                            &:not(:last-child){
                                &::after{
                                    content: "、";
                                }
                            }
                        }
                    }
                }
            }
            
            .keyword{
                @include rem(14);
                line-height: 1.43;
                font-weight: 400;
                
                span{
                    &:not(:last-child){
                        &::after{
                            content: "、";
                        }
                    }
                }
            }

            .corporate_tags{
                margin-top: 12px;
                @include rem(12);
                line-height: 1.5;
                color: #777;

                span{
                    &::before{
                        content: "#";
                    }

                    &:not(:last-child){
                        &::after{
                            content: "、";
                        }
                    }
                }
            }
        }
    }
}









.manual{
    position: relative;
    overflow: hidden;

    .manual_inner{
        padding: 60px 16px;

        @include media_query(){
            padding: 40px 16px;
        }

        .section_title{
            span{
                background-color: #fff;
            }
        }
    }

    .manual_contents{
        max-width: 960px;
        margin: 40px auto 0;
        display: grid;
        grid-template-columns: 338px 1fr;
        gap: 40px;
        
        
        @include media_query(1100){
            max-width: 760px;
            grid-template-columns: 1fr;
            
            img{
                margin: 0 auto;
            }
        }
        
        @include media_query(){
            grid-template-columns: 1fr;
            gap: 24px;
            
            img{
                max-width: 240px;
                margin: 0 auto;
            }
        }
        
        p{
            padding-top: 30px;
            @include rem(16);
            line-height: 1.6;
            
            small{
                @include rem(12);
            }
        }
        
        .btn_manual{
            margin-top: 24px;
            display: flex;
            justify-content: center;
            
            a{
                display: block;
                @include rem(18);
                font-weight: bold;
                color: #fff;
                text-decoration: none;
                text-align: center;
                border-radius: 4px;
                background: linear-gradient(136deg, #0085FF 0%, #72E3FB 100%);
                box-shadow: 6px 6px 0px 0px rgba(0, 0, 0, 0.10);
                padding: 15px 24px;
                
                transition: opacity .25s;
                
                &:hover{
                    opacity: .7;
                }
                
                @include media_query(){
                    padding: 15px 24px;
                    width: 100%;
                }
            }
        }
    }




    .decoration01 {
        top: 50px;
        left: 100px;
    }

    .decoration02 {
        top: -20px;
        right: 100px;
    }
}

.comingsoon{
    width: 100%;
    max-width: 520px;
    height: 190px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #F2F2F2;
    margin: 35px auto 0;

    @include media_query(){
        max-width: 340px;
        padding: 12px;
    }

    h3{
        @include rem(18);
        font-weight: 500;
        line-height: 1.43;
        margin-bottom: 10px;
    }

    p{
        @include rem(12);
        font-weight: 400;
        line-height: 1.43;

        @include media_query(){
            @include rem(10);
        }
    }
}





.other{
    position: relative;
    overflow: hidden;
    background-image: url(../img/dot.png);

    @include media_query(){
        padding-top: 60px;
        padding-bottom: 60px;
        margin-top: -35px;
    }

    &::before {
        display: block;
        content: "";
        width: 100%;
        aspect-ratio: 1366 / 190;
        background-image: url(../img/map-timetable.png);
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center top;

        @include media_query() {
            display: none;
        }
    }

    .other_inner{
        padding: 0 12px 120px;
        
        @include media_query(1100){
            max-width: 760px;
            margin: 0 auto;
        }

        @include media_query(){
            padding: 0 30px;
        }
    }

    .remarks{
        max-width: 800px;
        margin: 0 auto;
        margin-top: 40px;
        padding: 40px 24px;
        background-color: #fff;
        border-radius: 4px;

        .remarks_inner{
            max-width: 620px;
            margin: 0 auto;
        }

        h4{
            position: relative;
            text-align: center;
            margin-bottom: 16px;

            span{
                position: relative;
                z-index: 3;
                padding: 0 20px;
                background-color: #fff;
            }

            &::after{
                position: absolute;
                left: 0;
                top: 50%;
                z-index: 2;
                width: 100%;
                display: block;
                content: "";
                border-bottom: dotted 1px #334156;
            }
        }

        .remarks_contents{
            h5{
                @include rem(16);
                margin-bottom: 5px;
            }

            ul{
                padding-left: 1.5em;

                li{
                    @include rem(16);
                    line-height: 1.43;
                    font-family: 400;

                    @include media_query(){
                        @include rem(14);
                    }
                }

                & + h5{
                    margin-top: 16px;
                }
            }
        }
    }
    
    
    .timetable_modal_btn{
        position: relative;
        max-width: 800px;
        margin: 0 auto;
        
        .timetable_contents{
            @include media_query(){
                padding-bottom: 30px;
            }
        }
        
        .timetable_wrap{
            position: absolute;
            left: 0;
            top: 0;
            
            display: flex;
            justify-content: center;
            align-items: flex-end;
            
            width: 100%;
            height: 100%;
            padding-bottom: 24px;
            background: linear-gradient(rgba(#fff, .5), rgba(#fff, 1));
            
            .btn_timetable{
                button{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 236px;
                    height: 54px;
                    @include rem(18);
                    font-weight: bold;
                    text-decoration: none;
                    color: #fff;
                    background: linear-gradient(93.29deg, #0085FF 0%, #72E3FB 102.53%);
                    box-shadow: 6px 6px 0px rgba(0, 0, 0, 0.1);
                    border-radius: 4px;
                    transition: opacity .25s;

                    &:hover {
                        opacity: .7;
                    }

                    @include media_query(){
                        width: 266px;
                        height: 54px;
                        @include rem(14);
                        //box-shadow: none;
                        box-shadow: 3px 3px 0px rgba(0, 0, 0, 0.1);
                    }
                }
            }
        }
        
        .timetable_area{
            tr:last-child{
                @include media_query(){
                    display: none;
                }
            }
        }
    }
    
    
    
    
    .timetable_modal{
        visibility: hidden;
        opacity: 0;
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 999;
        display: flex;
        // flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100vh;
        height: 100dvh;
        background-color: rgba(0, 0, 0, 50%);
        
        transition: opacity .9s;

        /* これを追加 */
        overscroll-behavior: contain;
        overflow-y: scroll;
        scrollbar-width: none;
        -ms-overflow-style: none;
        touch-action: none;
        
        transition: opacity .25s;
        
        /* これを追加 */
        overscroll-behavior: contain;
        overflow-y: scroll;
        scrollbar-width: none;
        -ms-overflow-style: none;
        touch-action: none;
        
        &::before,
        &::after {
            content: "";
            width: 1px;
            height: calc(100vh + 1px);
            display: flex;
        }
        &::-webkit-scrollbar {
            display: none;
        }
        
        @include media_query(){
            padding: 16px;
        }
        
        &.open{
            visibility: visible;
            transform: scale(1);
            opacity: 1;
            
            .timetable_contents{
                transform: scale(1);
                opacity: 1;
            }
        }
        
        .timetable_contents_wrap{
            width: 100%;
            max-width: 800px;
        }
        
        .timetable_contents{
            transform: scale(0);
            opacity: 0;
            width: 100%;
            max-width: 800px;
            
            transition: transform .25s, opacity .25s;
            
            @include media_query(){
                max-height: 80vh;
                max-height: 80dvh;
                overflow: scroll;
            }
        }
    }
    
    .btn_modal_close{
        margin-top: 24px;
        text-align: center;
        @include rem(16);
        
        button{
            color: #fff;
            @include rem(16);
            
        }
    }






    .decoration01 {
        top: 200px;
        left: 100px;
    }

    .decoration02 {
        top: 500px;
        right: 100px;
        transform: rotate(180deg);
    }

    .decoration03 {
        top: 800px;
        left: 100px;
    }

    .decoration04 {
        bottom: 320px;
        left: 100px;
    }

    .decoration05 {
        bottom: 100px;
        right: 100px;
    }
}




.other_btns{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 32px;
    max-width: 800px;
    margin: 40px auto 0;

    @include media_query(){
        grid-template-columns: 1fr;
        gap: 24px;
    }

    .other_btn{
        a{
            display: block;
            background: linear-gradient(100deg, #83F0FF, #1099E5);
            padding: 4px;
            border-radius: 8px;
            color: #334156;
            text-decoration: none;
            line-height: 1.6;
            @include rem(19);
            font-weight: bold;
            box-shadow: 8px 8px 0px rgba(0, 0, 0, 0.05);

            @include media_query(){
                @include rem(18);
            }

            &:hover{
                opacity: .7;
            }

            .other_btn_inner{
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 0 30px 0 20px;
                background-color: #fff;
                border-radius: 5px;

                @include media_query(){
                    padding: 0 15px;

                    img{
                        width: 160px;
                        height: auto;
                    }
                }
            }
        }
    }
}




.application{
    position: relative;
    z-index: 3;
    margin-top: -60px;
    padding: 120px 16px 60px;
    //background: linear-gradient(-100deg, #83F0FF 8.7%, #1099E6 101.66%);
    background-image: url(../img/timetable-footer.png);
    background-position: center top;
    background-repeat: no-repeat;
    background-size: cover;

    @include media_query(){
        margin-top: -30px;
        padding: 60px 16px 30px;
        background-image: url(../img/timetable-footer_sp.png);
    }

    p{
        color: #fff;
        @include rem(32);
        font-weight: 900;
        line-height: 1.43;
        text-align: center;

        @include media_query(){
            @include rem(20);
        }
    }

    .application_btns{
        // max-width: 830px;
        // margin: 0 auto;
        margin-top: 25px;
        display: flex;
        justify-content: center;
        gap: 40px;
        
        @include media_query(){
            display: block;
        }
    }
    
    .bnt_application{
        flex: 1;
        max-width: 350px;
        margin: 25px auto 0;
        
        @include media_query(){
            width: 100%;
            margin: 8px auto 0;
            
            & + .bnt_application{
                margin-top: 12px;
            }
        }
        

        a{
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 75px;
            // margin: 0 auto;
            @include rem(24);
            font-weight: bold;
            text-decoration: none;
            color: #fff;
            background: linear-gradient(93.29deg, #FF7A00 0%, #FFA800 102.53%);
            box-shadow: 6px 6px 0px rgba(0, 0, 0, 0.1);
            border-radius: 4px;
            transition: opacity .25s;

            &:hover {
                opacity: .7;
            }
            
            span{
                @include rem(21);
            }

            @include media_query() {
                max-width: 300px;
                width: 100%;
                height: 50px;
                margin: 0 auto;
                @include rem(20);
                //box-shadow: none;
                box-shadow: 3px 3px 0px rgba(0, 0, 0, 0.1);
                
                span{
                    @include rem(18);
                }
            }
        }
    }
}
